// custom styles

// Estilos personalizados para os toasts
.toast-container {
  &.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 0;
    position: fixed;
    pointer-events: none;

    .ngx-toastr {
      width: 90%;
      max-width: 350px;
      margin: 0 auto 15px;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      pointer-events: auto;
    }
  }
}

// Ajustes para dispositivos móveis
@media (max-width: 576px) {
  .toast-container {
    &.toast-bottom-center {
      .ngx-toastr {
        width: 94%;
        margin-bottom: 10px;
        padding: 12px;
        font-size: 14px;

        .toast-title {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .toast-message {
          font-size: 14px;
        }
      }
    }
  }
}
